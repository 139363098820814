window._ = require('lodash');
import Picker from 'vanilla-picker';
window.Picker = Picker;


import Datepicker from 'vanillajs-datepicker/Datepicker';
window.Datepicker = Datepicker;
import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';
window.DateRangePicker = DateRangePicker;

import Swal from 'sweetalert2';
window.Swal = Swal;

//snackbar
import Snackbar from 'node-snackbar';
window.Snackbar = Snackbar;



/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('@popperjs/core');
    window.$ = window.jQuery = require('jquery');

    window.bootstrap = require('bootstrap');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });



require('datatables.net-dt');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');

//import datatables
import DataTable from 'datatables.net';
window.DataTable = DataTable;


//fullcalendar
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

//da locale
import daLocale from '@fullcalendar/core/locales/da';
window.daLocale = daLocale;
window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;


//import ckeditor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
window.ClassicEditor = ClassicEditor;

import daterangepicker from 'daterangepicker';
window.daterangepicker = daterangepicker;

import moment from 'moment';
window.moment = moment;

import select2 from 'select2';
window.select2 = select2;
